.gradient-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    225deg,
    rgba(131, 58, 180, 0.8) 0%,
    rgba(255, 63, 86, 0.8) 75%
  );
}

.login-btn {
  background-color: #fff0;
  padding: 10px 30px;
  color: #fff;
  font-size: 1.17em;
  font-weight: bolder;
  border: 2px solid #fff;
  border-radius: 10px;
  text-decoration: none;
  transition: 0.4s;
}

.login-btn:hover {
  border: 2px solid #000;
  background-color: #000;
  color: #fff;
}

.cover-height {
  height: 100vh;
}

.random-chars {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

#login-content {
  position: relative;
  padding-left: 5%;
}

@media (max-width: 900px) {
  #login-content {
    padding-left: 10%;
    background-image: linear-gradient(
        225deg,
        rgba(131, 58, 180, 0.8) 0%,
        rgba(255, 63, 86, 0.8) 75%
      ),
      url("../../assets/images/login-1.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
  }

  .random-chars {
    font-size: 1.65em;
  }

  .cover-height-mobile {
    height: 100vh;
  }

  .display-none-mobile {
    display: none !important;
  }

  .gradient-overlay {
    animation: none;
  }
}

/* CSS Transition class "item" */
.item-enter {
  z-index: -1;
  opacity: 0;
}
.item-enter-active {
  z-index: -1;
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  z-index: -1;
  opacity: 1;
}
.item-exit-active {
  z-index: -1;
  opacity: 0;
  transition: opacity 500ms ease-in;
}
